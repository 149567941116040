import { Autocomplete, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { countries } from "src/pages/admin/members/Profile/components/EditInfo/components/county-with-code.js";

const Country = ({ name, label }) => {
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const selected = watch(name);

  const value = countries?.find(({ iso2 }) => iso2 === selected) || null;

  return (
    <Autocomplete
      options={countries}
      value={value}
      getOptionLabel={({ name, phone_code, emoji }) =>
        `+ ${phone_code} - ${name} ${emoji}`
      }
      onChange={(_, v) => {
        setValue(name, v ? v.iso2 : null);
        setValue("phone_code", `+${v.phone_code}`);

        if (v) clearErrors(name);
        else
          setError(
            name,
            { message: "Country code is required" },
            { shouldFocus: true }
          );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            error={Boolean(errors[name])}
            helperText={t(errors[name]?.message)}
            label={t(label)}
            name={name}
          />
        );
      }}
    />
  );
};

export default Country;
